
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonLabel,
  IonItem,
  IonAvatar,
  IonSearchbar,
  IonToolbar,
  IonNote,
  IonImg,
  IonBadge,
  IonIcon,
  isPlatform,
} from "@ionic/vue";
import {
  ellipsisHorizontal,
  ellipsisVertical,
  personOutline,
  mailUnreadOutline,
  alertCircleOutline,
} from "ionicons/icons";

import { ref, defineComponent, computed } from "vue";
import { user } from "@/auth";

// my components & scripts
import router from "@/router";

import {
  listOfChats,
  loadingListOfChats,
} from "@/components/messages";

import MessagesSkeleton from "@/components/messages/MessagesSkeleton.vue";

export default defineComponent({
  name: "MessagesTab",
  components: {
    MessagesSkeleton,
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonLabel,
    IonImg,
    IonItem,
    IonAvatar,
    IonSearchbar,
    IonToolbar,
    IonNote,
    IonBadge,
    IonIcon,
  },
  setup() {
    // loadChats(); // called from Tabs.vue

    const sortByTimestamp = (a: any, b: any) => {
      if (a.lastMessage.timestamp > b.lastMessage.timestamp) {
        return -1;
      }
      if (a.lastMessage.timestamp < b.lastMessage.timestamp) {
        return 1;
      }
      return 0;
    };

    const query = ref("");
    const sorteredChatsByTime = computed(() => {
      if (query.value != "") {
        const filtered = listOfChats.value.filter((chat: any) => {
          if (chat.partnerProfile) {
            return chat.partnerProfile.name
              .toLowerCase()
              .includes(query.value.toLowerCase());
          } else {
            return false;
          }
        });
        return filtered.sort(sortByTimestamp);
      } else {
        return listOfChats.value.sort(sortByTimestamp);
      }
    });

    const parseTimestamp = (timestamp: number) => {
      const messageTime = new Date(timestamp);
      const currentTime = new Date();
      if (messageTime.toDateString() == currentTime.toDateString()) {
        return `${("0" + messageTime.getHours().toString()).slice(-2)}:${(
          "0" + messageTime.getMinutes().toString()
        ).slice(-2)}`;
      } else {
        return `${messageTime.getDate()}/${messageTime.getMonth()+1}/${messageTime.getFullYear()}`;
      }
    };

    const openChat = (chatId: string) => {
      router.push({
        path: `/messages/${chatId}`,
      });
    };

    return {
      user,
      isPlatform,
      sorteredChatsByTime,
      alertCircleOutline,
      query,
      parseTimestamp,
      openChat,
      listOfChats,
      loadingListOfChats,
      personOutline,
      ellipsisHorizontal,
      ellipsisVertical,
      mailUnreadOutline,
    };
  },
});
