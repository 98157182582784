<template>
  <div style="max-width: 500px; margin: auto">
    <ion-item>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h2>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
      <ion-note
        slot="end"
        color="primary"
        style="height: 100%; padding: 0; text-align: right; width: 3rem;"
      >
        <div style="height: 50%">
          <h6 style="margin: 0; padding-top: 0.5rem; color: grey">
            <ion-skeleton-text animated style="line-height: 13px;"></ion-skeleton-text>
          </h6>
        </div>
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h2>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
      <ion-note
        slot="end"
        color="primary"
        style="height: 100%; padding: 0; text-align: right; width: 3rem;"
      >
        <div style="height: 50%">
          <h6 style="margin: 0; padding-top: 0.5rem; color: grey">
            <ion-skeleton-text animated style="line-height: 13px;"></ion-skeleton-text>
          </h6>
        </div>
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h2>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
      <ion-note
        slot="end"
        color="primary"
        style="height: 100%; padding: 0; text-align: right; width: 3rem;"
      >
        <div style="height: 50%">
          <h6 style="margin: 0; padding-top: 0.5rem; color: grey">
            <ion-skeleton-text animated style="line-height: 13px;"></ion-skeleton-text>
          </h6>
        </div>
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h2>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
      <ion-note
        slot="end"
        color="primary"
        style="height: 100%; padding: 0; text-align: right; width: 3rem;"
      >
        <div style="height: 50%">
          <h6 style="margin: 0; padding-top: 0.5rem; color: grey">
            <ion-skeleton-text animated style="line-height: 13px;"></ion-skeleton-text>
          </h6>
        </div>
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h2>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
      <ion-note
        slot="end"
        color="primary"
        style="height: 100%; padding: 0; text-align: right; width: 3rem;"
      >
        <div style="height: 50%">
          <h6 style="margin: 0; padding-top: 0.5rem; color: grey">
            <ion-skeleton-text animated style="line-height: 13px;"></ion-skeleton-text>
          </h6>
        </div>
      </ion-note>
    </ion-item>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonNote,
  IonSkeletonText,
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonAvatar,
    IonItem,
    IonLabel,
    IonNote,
    IonSkeletonText,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
ion-skeleton-text {
  border-radius: 20px;
}

h2 ion-skeleton-text {
  line-height: 18px;
}

ion-item {
  border-radius: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

ion-item-divider ion-label {
  border-radius: 20px;
}
</style>
