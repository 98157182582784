<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t("tabs.messages") }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $t("tabs.messages") }}</ion-title>
        </ion-toolbar>
        <ion-toolbar>
          <ion-searchbar v-model="query"></ion-searchbar>
        </ion-toolbar>
      </ion-header>
      <div style="margin: auto; max-width: 500px">
        <div v-if="loadingListOfChats">
          <MessagesSkeleton />
        </div>
        <div v-else>
          <div v-if="listOfChats.length == 0">
            <div style="text-align: center; margin: 2rem">
              <!-- <ion-icon
                :icon="alertCircleOutline"
                style="font-size: 64px"
              ></ion-icon> -->
               <ion-img style="width:200px; margin:auto;" src="/assets/empty_states/messages.png"></ion-img>
              <h6 style="margin-top: 6px">{{ $t("messages.noMessages") }}</h6>
            </div>
          </div>
          <div v-else>
            <ion-item
              :style="
                isPlatform('desktop')
                  ? 'border-radius:20px;margin-top:.5rem;'
                  : ''
              "
              v-for="chat in sorteredChatsByTime"
              :key="chat.chatId"
              @click="openChat(chat.chatId)"
              button
            >
              <ion-avatar
                slot="start"
                style="
                  text-align: center;
                  background-color: #777;
                  height: 50px;
                  width: 50px;
                "
              >
                <div
                  v-if="!chat.partnerProfile?.profilePhoto"
                  style="height: 100%"
                >
                  <ion-icon
                    :icon="personOutline"
                    style="height: 100%; color: ghostwhite"
                  />
                </div>
                <img v-else :src="chat.partnerProfile.profilePhoto" />
              </ion-avatar>
              <ion-label>
                <h2>{{ chat.partnerProfile.name }}</h2>

                <p v-if="chat.lastMessage.type == 'text'">
                  {{ chat.lastMessage.text }}
                </p>
                <p v-else-if="chat.lastMessage.type == 'image'">
                  <span v-if="user?.uid == chat.lastMessage.sender">
                    {{ $t("messages.photoSent") }}
                  </span>
                  <span v-else>
                    {{
                      $t("messages.photoReceived", {
                        name: chat.partnerProfile.name,
                      })
                    }}
                  </span>
                </p>
                <p v-else-if="chat.lastMessage.type == 'video'">
                  <span v-if="user?.uid == chat.lastMessage.sender">
                    {{ $t("messages.videoSent") }}
                  </span>
                  <span v-else>
                    {{
                      $t("messages.videoReceived", {
                        name: chat.partnerProfile.name,
                      })
                    }}
                  </span>
                </p>
                <p v-else-if="chat.lastMessage.type == 'newContact'">
                  {{ $t("messages.newContact") }}
                </p>
              </ion-label>
              <ion-note
                slot="end"
                color="primary"
                style="height: 100%; padding: 0; text-align: right"
              >
                <div style="height: 50%">
                  <h6 style="margin: 0; padding-top: 0.5rem; color: grey">
                    {{ parseTimestamp(chat.lastMessage.timestamp) }}
                  </h6>
                </div>
                <div style="height: 50%; text-align: right">
                  <div v-if="chat.lastMessage.seen == false">
                    <ion-badge mode="ios" color="primary">
                      <ion-icon :icon="mailUnreadOutline"></ion-icon>
                    </ion-badge>
                  </div>
                </div>
              </ion-note>
            </ion-item>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonLabel,
  IonItem,
  IonAvatar,
  IonSearchbar,
  IonToolbar,
  IonNote,
  IonImg,
  IonBadge,
  IonIcon,
  isPlatform,
} from "@ionic/vue";
import {
  ellipsisHorizontal,
  ellipsisVertical,
  personOutline,
  mailUnreadOutline,
  alertCircleOutline,
} from "ionicons/icons";

import { ref, defineComponent, computed } from "vue";
import { user } from "@/auth";

// my components & scripts
import router from "@/router";

import {
  listOfChats,
  loadingListOfChats,
} from "@/components/messages";

import MessagesSkeleton from "@/components/messages/MessagesSkeleton.vue";

export default defineComponent({
  name: "MessagesTab",
  components: {
    MessagesSkeleton,
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonLabel,
    IonImg,
    IonItem,
    IonAvatar,
    IonSearchbar,
    IonToolbar,
    IonNote,
    IonBadge,
    IonIcon,
  },
  setup() {
    // loadChats(); // called from Tabs.vue

    const sortByTimestamp = (a: any, b: any) => {
      if (a.lastMessage.timestamp > b.lastMessage.timestamp) {
        return -1;
      }
      if (a.lastMessage.timestamp < b.lastMessage.timestamp) {
        return 1;
      }
      return 0;
    };

    const query = ref("");
    const sorteredChatsByTime = computed(() => {
      if (query.value != "") {
        const filtered = listOfChats.value.filter((chat: any) => {
          if (chat.partnerProfile) {
            return chat.partnerProfile.name
              .toLowerCase()
              .includes(query.value.toLowerCase());
          } else {
            return false;
          }
        });
        return filtered.sort(sortByTimestamp);
      } else {
        return listOfChats.value.sort(sortByTimestamp);
      }
    });

    const parseTimestamp = (timestamp: number) => {
      const messageTime = new Date(timestamp);
      const currentTime = new Date();
      if (messageTime.toDateString() == currentTime.toDateString()) {
        return `${("0" + messageTime.getHours().toString()).slice(-2)}:${(
          "0" + messageTime.getMinutes().toString()
        ).slice(-2)}`;
      } else {
        return `${messageTime.getDate()}/${messageTime.getMonth()+1}/${messageTime.getFullYear()}`;
      }
    };

    const openChat = (chatId: string) => {
      router.push({
        path: `/messages/${chatId}`,
      });
    };

    return {
      user,
      isPlatform,
      sorteredChatsByTime,
      alertCircleOutline,
      query,
      parseTimestamp,
      openChat,
      listOfChats,
      loadingListOfChats,
      personOutline,
      ellipsisHorizontal,
      ellipsisVertical,
      mailUnreadOutline,
    };
  },
});
</script>